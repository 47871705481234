import { render, staticRenderFns } from "./CardUserInformation.vue?vue&type=template&id=31b547bd&scoped=true&"
import script from "./CardUserInformation.vue?vue&type=script&lang=js&"
export * from "./CardUserInformation.vue?vue&type=script&lang=js&"
import style0 from "./CardUserInformation.vue?vue&type=style&index=0&id=31b547bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b547bd",
  null
  
)

export default component.exports