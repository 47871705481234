<template>
  <main class="columns is-multiline">
    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-9">
              <template v-if="modeEdit">
                <div class="columns is-multiline">
                  <div class="column is-4">
                    <div class="field">
                      <div class="control">
                        <p class="font_15 has_gray mb-2">
                          Nombre
                        </p>
                        <input
                          class="input px-3 py-3"
                          type="text"
                          placeholder="Nombre"
                          v-model="name"
                          name="name"
                          v-validate="'alpha_spaces|required'"
                          data-vv-as="del nombre"
                        >
                        <span v-show="vverrors.has('name')" class="help is-danger">{{ vverrors.first('name') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <div class="control">
                        <p class="font_15 has_gray mb-2">
                          Primer Apellido
                        </p>
                        <input
                          class="input px-3 py-3"
                          type="text"
                          placeholder="Primer Apellido"
                          v-model="lastName"
                          name="lastName"
                          v-validate="'alpha_spaces|required'"
                          data-vv-as="del primer apellido"
                        >
                        <span v-show="vverrors.has('lastName')" class="help is-danger">{{ vverrors.first('lastName') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <div class="control">
                        <p class="font_15 has_gray mb-2">
                          Segundo apellido
                        </p>
                        <input
                          class="input px-3 py-3"
                          type="text"
                          placeholder="Segundo apellido"
                          v-model="secondLastName"
                          name="secondLastName"
                          v-validate="'alpha_spaces|required'"
                          data-vv-as="del segundo apellido"
                        >
                        <span v-show="vverrors.has('secondLastName')" class="help is-danger">{{ vverrors.first('secondLastName') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ dataUser.name }} {{ dataUser.last_name }} {{ dataUser.second_last_name }}
                </p>
              </template>
            </div>
            <div class="column is-3 has-text-right-desktop has-text-right-tablet">
              <span class="font_15 has_gray has-text-weight-semibold mr-2">
                Estatus:
              </span>
              <template v-if="modeEdit">
                <span>
                  <input
                    id="switchStatus"
                    type="checkbox"
                    class="switch is-rounded"
                    :checked="status ? 'checked' : ''"
                    v-model="status"
                    name="switchStatusStatus"
                  >
                  <label for="switchStatus"></label>
                </span>
              </template>
              <template v-else>
                <span
                  class="font_15 has-text-weight-semibold"
                  :class="[ status ? 'has_success' : 'has_danger' ]"
                >
                  {{ status === true ? 'Activo' : 'Inactivo'}}
                </span>
              </template>
            </div>

            <div class="column is-3">
              <p class="font_15 has_gray">
                Celular/Whatsapp:
              </p>
            </div>
            <div class="column is-9">
              <template v-if="modeEdit">
                <div class="field">
                  <div class="control">
                    <input
                      class="input px-3 py-3"
                      type="text"
                      placeholder="Celular/Whatsapp"
                      v-model="phone"
                      name="phone"
                      maxlength="10"
                      v-validate="'digits:10'"
                      @keypress="onlyNumbers"
                    >
                  </div>
                </div>
                <span v-show="vverrors.has('phone')" class="help is-danger">{{ vverrors.first('phone') }}</span>
              </template>
              <template v-else>
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ dataUser.phone }}
                </p>
              </template>
            </div>

            <div class="column is-3">
              <p class="font_15 has_gray">
                Correo electrónico:
              </p>
            </div>
            <div class="column is-9">
              <p class="font_15 has_gray has-text-weight-semibold">
                {{ dataUser.email }}
              </p>
            </div>

            <div class="column is-3">
              <p class="font_15 has_gray">
                Tipo de persona:
              </p>
            </div>
            <div class="column is-9">
              <template v-if="modeEdit">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="personType" name="personType" v-validate="'required'" data-vv-as="del tipo de persona">
                        <option selected disabled>Tipo de persona</option>
                        <option v-for="item in personTypeList" :key="item.id" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <span v-show="vverrors.has('personType')" class="help is-danger">{{ vverrors.first('personType') }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ personName || 'Sin seleccionar' }}
                </p>
              </template>
            </div>

            <div class="column is-3">
              <p class="font_15 has_gray">
                Razón Social:
              </p>
            </div>
            <div class="column is-9">
              <template v-if="modeEdit">
                <div class="field">
                  <div class="control">
                    <input
                      class="input px-3 py-3"
                      type="text"
                      placeholder="Razón Social"
                      v-model="businessName"
                      name="businessName"
                      v-validate="'required'"
                      data-vv-as="de la razón social"
                    >
                    <span v-show="vverrors.has('businessName')" class="help is-danger">{{ vverrors.first('businessName') }}</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <p class="font_15 has_gray has-text-weight-semibold">
                  {{ businessName || 'N/A' }}
                </p>
              </template>
            </div>

            <div class="column is-3">
              <p class="font_15 has_gray">
                Estatus del contrato
              </p>
            </div>
            <div class="column is-9" v-if="!modeEdit">
              <template v-if="contractCurrent.id">
                <p
                  class="font_15 has_gray has-text-weight-semibold"
                  :class="[ contractCurrent.status === IS_PENDING ? 'has_warning' : (contractCurrent.status === IS_APPROVED ? 'has_success' : 'has_danger') ]"
                >
                  {{ contractCurrent.contract_status_name }}
                </p>
              </template>
              <template v-else>
                <p class="font_15 has_gray has-text-weight-semibold">Sin información</p>
              </template>
            </div>
            <div class="column is-9" v-else>
              <div class="field">
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-model="contractStatus"
                      name="contractStatus"
                      v-validate="'required'"
                      data-vv-as="del estatus del contrato"
                    >
                      <option selected disabled>Estatus del contrato</option>
                      <option v-for="item in contractStatusList" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <span v-show="vverrors.has('contractStatus')" class="help is-danger">{{ vverrors.first('contractStatus') }}</span>
              </div>
            </div>
            <div class="column is-7">
              <div class="block">
                <article class="media media_file">
                  <figure class="media-left" v-if="contractCurrent.contract_file">
                    <a class="image" :href="`${BUCKET_NAME}${contractCurrent.contract_file}`" target="_blank">
                      <img class="image_pdf" src="@/assets/icon/pdf_file.png" alt="PDF">
                    </a>
                  </figure>
                  <div class="media-content">
                    <div class="content my-2 mx-2 p-2">
                      <template v-if="!contractCurrent.contract_file">
                        <p class="has_text_18 has_text_blue">
                          El usuario aun no sube su contrato, si deseas subir el archivo da clic en editar.
                        </p>
                      </template>
                      <template v-if="contractCurrent.contract_file && contractCurrent.status === IS_REJECTED">
                        <p class="has_text_18 has_text_blue">
                          El último contrato ha sido rechazado, si deseas subir el archivo da clic en <span>{{ !modeEdit ? 'editar' : 'actualizar' }}</span>.
                        </p>
                      </template>
                      <template v-else>
                        <p class="has_text_18 has_text_blue dont_break_out">
                          {{ contractCurrent.contract_file }}
                        </p>
                      </template>
                    </div>
                  </div>
                </article>
              </div>
              <div class="block" v-if="modeEdit && (!contractCurrent.id || contractCurrent.status === IS_REJECTED)">
                <article class="media media_file" v-if="fileInput">
                  <figure class="media-left">
                    <a class="image">
                      <img class="image_pdf" src="@/assets/icon/pdf_file.png" alt="PDF">
                    </a>
                  </figure>
                  <div class="media-content">
                    <div class="content my-2 mx-2 p-2">
                      <p class="has_text_18 has_text_blue">
                        <b>Nuevo contrato:</b> <br>
                        {{ fileName }}
                      </p>
                    </div>
                  </div>
                </article>
                <div class="file">
                  <label class="file-label">
                    <input
                      ref="fileDocumentRef"
                      accept=".pdf"
                      name="fileDocument"
                      data-vv-as="del archivo"
                      class="file-input"
                      type="file"
                      v-validate="'ext:pdf|size:7170'"
                      @change="setMyFile"
                    >
                    <span class="file-cta">
                      <p class="file-label">
                        {{ contractCurrent.status === IS_REJECTED ? 'Actualizar' : 'Subir' }}
                      </p>
                    </span>
                  </label>
                </div>
                <div class="block has-text-centered mt-4">
                  <span v-show="vverrors.has('fileDocument')" class="help is-danger">{{ vverrors.first('fileDocument') }}</span>
                </div>
              </div>
            </div>

            <div class="column is-12" v-if="modeEdit">
              <div class="field is-grouped is-justify-content-flex-end">
                <div class="control">
                  <button
                    class="button is_outlined"
                    :disabled="loading"
                    @click="$emit('cancel-edit')"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="control">
                  <button
                    class="button btn_violet"
                    :class="{ 'is-loading' : loading }"
                    @click="saveInformation()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-12">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-12">
              <p class="font_15 has_gray has-text-weight-semibold">
                Historial de contratos
              </p>
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <template v-if="contractHistoric.length > 0">
                  <div
                    class="column is-12"
                    v-for="item in contractHistoric"
                    :key="`contract_${item.id}`"
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-3">
                        <p class="has_text_18 has_text_blue">
                          {{ moment(item.created_at).format('YYYY-MM-DD') }}
                        </p>
                      </div>
                      <div class="column is-5">
                        <p class="has_text_18 has_text_blue dont_break_out">
                          {{ item.contract_file }}
                        </p>
                      </div>
                      <div class="column is-4">
                        <p
                          class="has_text_18 has_text_blue has-text-right has-text-weight-semibold"
                          :class="[ item.status === IS_PENDING ? 'has_warning' : (item.status === IS_APPROVED ? 'has_success' : 'has_danger') ]"
                        >
                          {{ item.contract_status_name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CardUserInformation',
  props: {
    dataUser: {
      type: Object,
      default: () => {}
    },
    contractsUser: {
      type: Array,
      default: () => []
    },
    modeEdit: {
      type: Boolean,
      default: false
    },
    updateUser: {
      type: Function
    }
  },
  data () {
    return {
      moment: moment,
      BUCKET_NAME: process.env.VUE_APP_BUCKET_NAME,
      IS_PENDING: 1,
      IS_APPROVED: 2,
      IS_REJECTED: 3,
      loading: false,
      name: null,
      lastName: null,
      secondLastName: null,
      status: false,
      phone: null,
      personType: null,
      personName: null,
      businessName: null,
      contractStatus: null,
      signedContract: null,
      fileName: null,
      fileInput: null,
      statusFile: false,
      idDocument: null,
      personTypeList: [
        {
          id: 1, name: 'Persona Física'
        },
        {
          id: 2, name: 'Persona Física con Actividad empresarial'
        },
        {
          id: 3, name: 'RIF'
        },
        {
          id: 4, name: 'Persona Moral'
        }
      ],
      contractStatusList: [
        {
          id: 1,
          name: 'Pendiente'
        },
        {
          id: 2,
          name: 'Aprobado'
        },
        {
          id: 3,
          name: 'Rechazado'
        }
      ],
      contractCurrent: {},
      contractHistoric: []
    }
  },
  methods: {
    setMyFile (event) {
      if (event) {
        const [file] = event.target.files
        if (file) {
          this.fileName = file.name
          this.fileInput = { key: event.target.name, file }
          this.contractStatus = this.IS_APPROVED
        }
      }
    },
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async spreadData () {
      this.name = this.dataUser.name
      this.lastName = this.dataUser.last_name
      this.secondLastName = this.dataUser.second_last_name
      this.status = this.dataUser.active
      this.phone = this.dataUser.phone
      this.personType = this.dataUser.person_type
      this.personName = this.dataUser.person_name
      this.businessName = this.dataUser.business_name || 'N/A'
      // Obtenemos el contrato actual
      this.contractCurrent = this.contractsUser.reduce((prev, current) => {
        return +current.id > +prev.id ? current : prev
      })
      // Obtenemos el historico
      this.contractHistoric = this.contractsUser.filter((contract) => contract.status === this.IS_REJECTED)
      // this.contracts = this.contractsUser
      this.contractStatus = this.contractCurrent.status
    },
    async saveInformation () {
      if (await this.$validator.validateAll()) {
        this.loading = true
        const userInfo = {
          name: this.name,
          lastName: this.lastName,
          secondLastName: this.secondLastName,
          phone: this.phone,
          personType: this.personType,
          businessName: this.businessName,
          contractStatus: this.contractStatus,
          contractStatusName: this.contractStatusName,
          fileName: this.fileName,
          fileInput: this.fileInput ? this.fileInput.file : null,
          statusFile: this.contractStatus,
          idDocument: this.contractCurrent.id
        }
        await this.updateUser(userInfo)
        this.loading = false
      }
    }
  },
  beforeMount () {
    this.spreadData()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 8px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
  }

  .media.media_file {
    border-radius: 10px;
    border: solid 1px #d5d5d5;
    background-color: #fff;
    align-items: center;
  }
  .image_pdf {
    width: 30px;
    margin: 1rem 1.5rem 1rem 2rem;
  }

  .file {
    justify-content: center;
  }

  .file-cta {
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    color: #fff;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 1.23;
    font-weight: 600;
    transition: all 150ms ease-in-out;
    justify-content: center;
  }
  .file-cta:hover {
    border-radius: 8px;
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 0 2px 0 #96d064 inset, 0 0 2px 1px #96d064;
    color: white;
  }
  .file-cta.is-disabled,
  .file-cta.is-disabled:hover {
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    color: white;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.2;
  }

  .file-cta, .button {
    width: 160px;
  }

  .switch[type="checkbox"]:checked + label::before,
  .switch[type="checkbox"]:checked + label:before {
    background: #96d064;
  }

  .dont_break_out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  @media screen and (max-width: 768px) {
    .button {
      width: 100% !important;
    }
  }
</style>
